<template>
  <v-container class="pa-0" fluid>
    <v-list-item :to="{name: 'branches', params: {site_id: site.id}}">
      <v-row align="center" class="text-start" justify="center" no-gutters>
        <v-list-item-avatar class="my-auto">
          <v-img :alt="`${site.name} logo`" :aspect-ratio="16/9" :src="site.logo" contain/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="`<b>${site.name}</b>`"/>
          <v-list-item-subtitle v-text="site.category.name"/>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-subtitle
            v-html="`<b>${$vuetify.lang.t('$vuetify.pages.more.branchCount')}</b>${site.branches_count}`"/>
          <v-list-item-subtitle v-html="`<b>${$vuetify.lang.t('$vuetify.pages.more.enabled')}</b>
          ${site.enabled ? $vuetify.lang.t('$vuetify.pages.more.yes'): $vuetify.lang.t('$vuetify.pages.more.no')}`"/>
        </v-list-item-content>
      </v-row>
    </v-list-item>
    <slot/>
  </v-container>
</template>

<script>
import Site from '@/modules/app/models/site'

export default {
  name: 'KurccSiteListItem',
  props: {
    site: {
      type: Site,
      required: true
    }
  }
}
</script>
